import * as $ from "jquery";

$(document).on('turbolinks:load', function () {
  // form validation
  if ($('.input-wrap')[0]) {
    $('input[type="submit"], button[type="submit"]').on('click', function (e) {

      if ($(this).hasClass('disabled') && $('.page-user-register')[0]) {
        e.preventDefault();
        $('.caution-balloon.agree').show();
        return false;
      }

      var validateArray = [];
      $('.input-wrap').each(function () {
        var $input = $(this).find('input');
        validateArray.push(checkBlank($input));
        // if ($input.hasClass('check-kana')) validateArray.push(checkOnlyKana($input));
        if ($input.hasClass('check-email')) validateArray.push(checkEmailStyle($input));
        if ($input.hasClass('check-same-email')) validateArray.push(checkSameEmail($input));
        if ($input.hasClass('check-same-password')) validateArray.push(checkSamePassword($input));
      });
      if (!validateArray.every(function (v) {
        return v
      })) {
        e.preventDefault();
        slideErrorPosition();
      }
    });

    // remove caution balloon
    $('.input-wrap input').on('focus', function () {
      $(this).closest('.input-wrap').find('.caution-balloon').fadeOut();
    });
  }

  function checkBlank($target) {
    var $val = $target.val();
    if ($val == '') {
      var errorMessage = 'このフォームを入力してください。';
      showCautionBalloon($target, errorMessage);
      return false;
    } else {
      return true;
    }
  }

  function checkOnlyKana($target) {
    var $val = $target.val();
    if (!$val.match(/^[ァ-ヶー]*$/)) {
      var errorMessage = '全角カタカナのみを入力してください。';
      showCautionBalloon($target, errorMessage);
      return false;
    } else {
      return true;
    }
  }

  function checkEmailStyle($target) {
    var $val = $target.val();
    if (!$val.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)) {
      var errorMessage = '正しいメールアドレスを入力してください。';
      showCautionBalloon($target, errorMessage);
      return false;
    } else {
      return true;
    }
  }

  function checkSameEmail($target) {
    var $val_1 = $('.check-same-email').eq(0).val();
    var $val_2 = $('.check-same-email').eq(1).val();
    if ($val_1 != $val_2) {
      var errorMessage = '同じメールアドレスを入力してください。';
      showCautionBalloon($target, errorMessage);
      return false;
    } else {
      return true;
    }
  }

  function checkSamePassword($target) {
    var $val_1 = $('.check-same-password').eq(0).val();
    var $val_2 = $('.check-same-password').eq(1).val();
    if ($val_1 != $val_2) {
      var errorMessage = '同じパスワードを入力してください。';
      showCautionBalloon($target, errorMessage);
      return false;
    } else {
      return true;
    }
  }

  function showCautionBalloon($target, text) {
    var $cautionBalloon = $target.closest('.input-wrap').find('.caution-balloon');
    $cautionBalloon.show().find('p').text(text);
  }

  function slideErrorPosition() {
    $('.caution-balloon').each(function () {
      if ($(this).css('display') != 'none') {
        var target = $(this).offset().top;
        target = target - $(window).height() / 2;
        $('body, html').animate({'scrollTop': target}, 400, 'easeOutCubic');
        return false;
      }
    });
  }
});

// easing 設定
jQuery.extend(jQuery.easing,
  {
    easeOutCubic: function (x, t, b, c, d) {
      return c * ((t = t / d - 1) * t * t + 1) + b;
    }
  });